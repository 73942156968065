/* eslint-disable jsx-a11y/anchor-is-valid */
import { Link } from "react-router-dom";
import HomeCoverYoutube from "./HomeCoverYoutube";
import { useState } from "react";

export default function HomeCoverSec() {

    const [isPopupVisible, setIsPopupVisible] = useState(false);

    const togglePopup = () => { setIsPopupVisible(!isPopupVisible); };

    return (
        <div>
            <section className="uk-section uk-section-default uk-section-large uk-padding-remove-bottom uk-background-cover uk-background-norepeat" style={{ backgroundImage: `url('/images/section-bg-03.jpg')` }}>
                <div className="uk-container">
                    <div className="uk-grid-large uk-flex-middle" uk-grid="true">
                        <div className="uk-width-1-2@m">
                            <div uk-scrollspy="cls: uk-animation-slide-bottom-medium; target: > *; delay: 250;">
                                <h1 className="uk-text-primary uk-heading-small">ezone</h1>
                                <p className="uk-text-lead uk-light">
                                    إنتقل من التجارة التقليدية إلى التجارة الإلكترونية بكل يسر وأنشيء متجرك الخاص خلال دقائق وعزز تجارتك بالأدوات التي توفرها لك منصة إيزون.
                                </p>
                                <div className="uk-margin-large uk-margin-remove-top@s">
                                    <Link className="uk-button uk-button-primary uk-button-large uk-width-1-1 uk-width-1-2@m" to="/register">
                                        أنشىء متجرك الأن
                                        <span className="uk-margin-small-right" uk-icon="bag"></span>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="uk-width-1-2@m">
                            <a onClick={togglePopup} className="uk-visible@m" ><img src="/images/home-hero-img.svg" alt="" uk-scrollspy="cls:uk-animation-slide-left-medium" /></a>
                            <div className="uk-margin-large-bottom uk-margin-medium-top video-block">
                                <a onClick={togglePopup} className="uk-child-width-auto uk-grid-collapse uk-flex-middle uk-flex-center" uk-grid="true">
                                    <div uk-icon="icon: youtube; ratio: 2" className="uk-margin-left"></div>
                                    <div className="uk-h3 uk-light">تعرف على خدمات ezone في دقيقة</div>
                                </a>
                            </div>
                        </div>
                        <HomeCoverYoutube isPopupVisible={isPopupVisible} togglePopup={togglePopup} />
                    </div>
                </div>
            </section>

            <section className="uk-section uk-section-primary uk-section-large uk-background-contain uk-background-norepeat" style={{ backgroundImage: `url('/images/section-bg-01.png')` }}>
                <div className="uk-container uk-container-small uk-text-center" uk-scrollspy="cls: uk-animation-slide-bottom-medium; target: > *; delay: 250;">
                    <div uk-scrollspy="cls: uk-animation-slide-bottom-medium; target: > *; delay: 250;">
                        <h2 className="uk-heading-small">ezone</h2>
                        <p className="uk-text-lead font-bold">
                            ايـزون عـــلامـة تـجـاريـة تـأسـسـت فـي عــام 2021 تـهــدف الـي تمكيـن قـطـاع التجزئة من الدخول الى عالم التجزئة الالكترونـيـة بطريقة سهلة واحترافية
                        </p>
                    </div>
                </div>
            </section>
        </div>
    );



}