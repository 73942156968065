export default function HomeCoverYoutube({ isPopupVisible, togglePopup }) {

    const handleOverlayClick = (e) => {
        // Close popup only if the user clicks on the overlay (not the video container)
        if (e.target.id === "overlay") {
          togglePopup();
        }
      };


      return (
        <div>
              
          {/* Popup */}
          {isPopupVisible && (
            <div
              id="overlay"
              style={styles.overlay}
              onClick={handleOverlayClick} // Detect clicks on the overlay
            >
              <div style={styles.popup}>
                <button onClick={togglePopup} style={styles.closeButton}>
                  ×
                </button>
                {/* Responsive YouTube Video */}
                <div style={styles.videoContainer}>
                  <iframe
                    src="https://www.youtube-nocookie.com/embed/qHdSUHELIn0" // Replace with your YouTube video link
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    style={styles.iframe}
                  ></iframe>
                </div>
              </div>
            </div>
          )}
        </div>
      );
}

const styles = {
    
    overlay: {
      position: "fixed",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      backgroundColor: "rgba(0, 0, 0, 0.7)",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      zIndex: 1000,
    },
    popup: {
      position: "relative",
      background: "#fff",
      padding: "10px",
      borderRadius: "10px",
      textAlign: "center",
      boxShadow: "0 5px 15px rgba(0, 0, 0, 0.3)",
      maxWidth: "90%", // Prevent overflow on smaller screens
      width: "600px",  // Fixed width for larger screens
    },
    closeButton: {
      position: "absolute",
      top: "10px",
      right: "10px",
      background: "none",
      border: "none",
      fontSize: "20px",
      cursor: "pointer",
    },
    videoContainer: {
      position: "relative",
      width: "100%",
      paddingTop: "56.25%", // 16:9 aspect ratio
      overflow: "hidden",
      borderRadius: "10px",
    },
    iframe: {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      border: "none",
    },
  };